import axios from "axios";

const SESSION_TIMEOUT = 9000000;
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

let sessionTimer;

const handleSessionExpiration = () => {
  localStorage.removeItem("wineryToken");
  localStorage.removeItem("otp_token");
  window.location.replace("/login");
};

const axiosInstance = axios.create({
  baseURL: `https://api.bookwineries.com/`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("wineryToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Content-Security-Policy"] = "default-src 'self'";
    config.headers["Strict-Transport-Security"] =
      "max-age=31536000; includeSubDomains; preload";
    config.headers["X-Content-Type-Options"] = "nosniff";
    config.headers["X-Frame-Options"] = "DENY";
    config.headers["Referrer-Policy"] = "no-referrer";
    config.headers["X-XSS-Protection"] = "1; mode=block";
    config.headers["timezone"] = timeZone;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    localStorage.setItem("sessionTimestamp", Date.now().toString());

    clearTimeout(sessionTimer);
    sessionTimer = setTimeout(handleSessionExpiration, SESSION_TIMEOUT);

    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      clearTimeout(sessionTimer);
      handleSessionExpiration();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
