import axiosInstance from "../AxiosInstance";
import {
  ADD_STORE,
  GET_STORE,
  EDIT_STORE,
  DELETE_STORE,
  ADD_PROGRAM,
  GET_OFFERS,
  ADD_OFFER,
  EDIT_OFFER,
} from "./StoreApiEndPoints";

export async function addStore(formData) {
  const response = await axiosInstance.post(ADD_STORE, formData);
  return response;
}
export async function editStore(formData) {
  const response = await axiosInstance.put(EDIT_STORE, formData);
  return response;
}
export function getStore(currentPage, limit) {
  return axiosInstance.get(GET_STORE + `?page=${currentPage}&limit=${limit}`);
}
export function getStoreDetailsApi(id) {
  return axiosInstance.get(`vendor/getStoreDetail?storeId=${id}`);
}

export function deleteStore(id) {
  return axiosInstance.delete(DELETE_STORE + `?StoreId=${id}`);
}

export async function addLoyaltyProgramApi(formData) {
  const response = await axiosInstance.post(ADD_PROGRAM, formData);
  return response;
}

//////////////////////////////////////////Offers API================================
export function getOfferApi(id) {
  return axiosInstance.get(GET_OFFERS + `?programId=${id}`);
}
export async function addOfferApi(formData) {
  const response = await axiosInstance.post(ADD_OFFER, formData);
  return response;
}
export async function editOfferApi(formData) {
  const response = await axiosInstance.put(EDIT_OFFER, formData);
  return response;
}

export async function addTierApi(silverTier, bronzeTier, goldTier, storeId) {
  const postData = {
    silver: silverTier.silver,
    silverStatus: silverTier.silverStatus,
    bronze: bronzeTier.bronze,
    bronzeStatus: bronzeTier.bronzeStatus,
    gold: goldTier.gold,
    goldStatus: goldTier.goldStatus,
    storeId: storeId,
  };
  const response = await axiosInstance.put("vendor/setTiers", postData);
  return response;
}
export function blockWinery(id) {
  const postData = { wineryId: id };
  return axiosInstance.put("admin/user/disableWinery", postData);
}
export function topWineryApi(postData) {
  return axiosInstance.post("admin/home/topWinery", postData);
}

////////////////////////////////WINERY_API // //////////////////////////////////////////////////////////////////
export function getWineryListApi(currentPage, itemPerPage, search, type) {
  return axiosInstance.get(
    `admin/home/countWineries?page=${currentPage}&limit=${itemPerPage}&search=${search}&category=${type}`
  );
}
export function getWineryDetailsApi(id) {
  return axiosInstance.get(`admin/home/wineryDetails?wineryId=${id}`);
}
export function deleteWineryApi(id) {
  return axiosInstance.delete("admin/home/deleteWinery" + `?wineryId=${id}`);
}
