import React, { useEffect, useState } from "react";
import { Badge, Card, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import defaultImage from "../../images/emptyImg.jpg";

import Pagination from "../common/Pagination";
import { getUserBookingApi } from "../../services/User/UserService";

function UserDetails(props) {
  const userData = props.location?.state;
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [status, setStatus] = useState("total");

  const limit = 10;

  function handleError(e) {
    e.target.src = defaultImage;
  }

  function getTableData() {
    setLoader(true);
    getUserBookingApi(userData._id, currentPage, limit, status)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, [userData?.id, currentPage, status]);

  return (
    <div>
      {" "}
      <div className="page-titles">
        <h4>User Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/user-management">User Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>User Details</Link>
          </li>
        </ol>
      </div>
      <Card>
        <Card.Header>
          <div className="d-flex mt-1" style={{ gap: "1rem", flexGrow: "1" }}>
            <div>
              <img
                src={`https://api.bookwineries.com/${userData?.profilePicture}`}
                width={130}
                height={150}
                className="text-center"
                onError={handleError}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="text-left" style={{ flex: "1" }}>
              <div className="d-flex justify-content-between align-items-center">
                <h5 style={{ margin: "0" }}>Name</h5>
                <p className="m-0">{userData?.name}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 style={{ margin: "0" }}>created At</h5>
                <p style={{ margin: "0" }}>
                  {moment(userData?.createdAt).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 style={{ margin: "0" }}>Email</h5>
                <p style={{ margin: "0" }}>{userData?.email}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="m-0">References</h5>
                <p className="m-0">{userData?.refUser?.length || 0}</p>
              </div>

              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5 className="m-0">Phone</h5>
                <p className="m-0">
                  <span>+{userData?.countryCode} </span>
                  {userData?.contact || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
            <div className="form-group mr-3" style={{ width: "25%" }}>
              <select
                className="form-control"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="" disabled>
                  Select Bookings...
                </option>
                <option value="total">All</option>
                <option value="active">Active Bookings</option>
                <option value="confirmed">Completed Bookings</option>
                <option value="cancel">Canceled Bookings</option>
              </select>
            </div>
            <Card.Header className="p-0 pt-1">
              <h3>Bookings</h3>
            </Card.Header>
            <>
              {loader ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>winery name</strong>
                      </th>
                      <th>
                        <strong>category</strong>
                      </th>
                      <th className="text-center">
                        <strong>Guests</strong>
                      </th>
                      <th>
                        <strong>Prize</strong>
                      </th>
                      <th>
                        <strong>Booking Slot</strong>
                      </th>
                      <th>
                        <strong>Date</strong>
                      </th>
                      <th className="text-center">
                        <strong>Canceled Date & Time</strong>
                      </th>

                      <th>
                        <strong>Status</strong>
                      </th>
                    </tr>
                  </thead>

                  <tbody style={{ maxHeight: "420px", overflow: "scroll" }}>
                    {data?.map((item, i) => (
                      <tr key={i}>
                        <td>{item.wineryId.name}</td>

                        <td>{item?.wineryId.category || "N/A"}</td>
                        <td className="text-center">{item?.guests} </td>

                        <td>€{item?.amount}</td>

                        <td>
                          {moment(item?.startTime, "HH:mm:ss").format(
                            "hh:mmA"
                          ) || "N/A"}
                          -
                          {
                            (item?.endTime,
                            moment(item?.endTime, "HH:mm:ss").format("hh:mmA"))
                          }
                        </td>

                        <td>{moment(item?.date).format("DD/MM/YYYY")}</td>
                        {item?.cancelledDate ? (
                          <td className="text-center">
                            {moment(item?.cancelledDate).format(
                              "DD/MM/YYYY HH:mm A"
                            )}
                          </td>
                        ) : (
                          <td className="text-center">-</td>
                        )}

                        <td>
                          {item.status === "confirm" ? (
                            <Badge variant="success light">Completed</Badge>
                          ) : item.status === "cancel" ? (
                            <Badge variant="danger light">Cancelled</Badge>
                          ) : (
                            <Badge variant="info light">Active</Badge>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {data?.length === 0 && !loader && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
              <div className="d-flex justify-content-end">
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {};
export default connect(mapStateToProps)(UserDetails);
