import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import toast, { Toaster } from "react-hot-toast";
import PageTitle from "../../layouts/PageTitle";
// import Button from "../../components/Button";
import {
  getTermsServicesApi,
  policiesServicesApi,
} from "../../../services/User/UserService";
export default function Terms() {
  const [languageText, setLanguageText] = useState("");
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("user");
  const [languageTextError, setLanguageTextError] = useState("");
  const handleChangeClinical = (content) => {
    setLanguageText(content);
    setLanguageTextError("");
  };

  function getTableData() {
    setLoader(true);
    getTermsServicesApi(type)
      .then((response) => {
        setLanguageText(
          type === "user"
            ? response?.data?.data[0]?.policy?.termsAndConditionsUser
            : response?.data?.data[0]?.policy?.termsAndConditions
        );
        console.log(response);
        setLoader(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.data);
        setLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, [type]);

  async function handleSubmitTerm(event) {
    event.preventDefault();

    if (languageText?.trim() === "") {
      setLanguageTextError("Terms and Conditions cannot be empty");
      return;
    }
    setLoader(true);
    try {
      const response = await policiesServicesApi(languageText, "terms", type);
      if (response?.status === 200) {
        setLoader(false);
        toast.success("Terms & Conditions Uploaded successfully");
      } else if (!response?.status === 200) {
        throw new Error("response.message");
      }
      setLanguageText("");
    } catch (error) {
      setLoader(false);
      toast.error(`Error: ${error?.response?.data?.data}`);
    }
  }

  return (
    <div>
      <PageTitle activeMenu="Terms & Condition" />
      <div className="d-flex justify-content-start mb-3">
        <select
          style={{
            color: "#7e7e7e",
            padding: " 10px",
            borderColor: "lightgrey",
            borderRadius: "6px",
          }}
          value={type}
          onChange={(event) => setType(event.target.value)}
          required
        >
          <option hidden>Select Type</option>
          <option value="user">User </option>
          <option value="vendor">Vendor </option>
        </select>
      </div>

      <Editor
        apiKey="puvcmpus1ty74pn05yqv6et6rdglxvo4q1lkxt0vh9yx2dc3"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image code charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code textcolor wordcount",
            "textarea",
            "textcolor",
            "forecolor backcolor",
          ],
          toolbar:
            "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
            "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
          content_style: "body { color: #000 }",
        }}
        onEditorChange={handleChangeClinical}
        name="prescription"
        value={languageText}
      />
      <span className="text-danger">{languageTextError}</span>

      <div className="d-flex justify-content-end mt-4">
        <button
          className={`btn btn-sm btn-primary ${loader ? "disabled" : ""}`}
          disabled={loader}
          style={
            loader
              ? { pointerEvents: "none", opacity: 0.5 }
              : { pointerEvents: "auto" }
          }
          onClick={(e) => {
            handleSubmitTerm(e);
          }}
        >
          {loader ? (
            <div
              className="d-flex align-items-center justify-content-center "
              style={{ gap: "5px" }}
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </div>
          ) : (
            "Send"
          )}
        </button>
      </div>
    </div>
  );
}
