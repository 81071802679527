import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import defaultImage from "../../images/emptyImg.jpg";
import Pagination from "../common/Pagination";
import toast from "react-hot-toast";
import {
  getWineryListApi,
  deleteWineryApi,
  blockWinery,
} from "../../services/Store/StoreServices";
import Switch from "react-switch";
import DeletePopup from "../modal/DeletePopup";
import SelectForTopWineryModal from "../modal/SelectForTopWineryModal";
import moment from "moment";
import { gerExportFileApi } from "../../services/User/UserService";

export default function WineryManagement(props) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.content}
    </Tooltip>
  );
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [type, setType] = useState("");
  const [isSelectWineryModal, setIsSelectWineryModal] = useState(false);
  const [id, setId] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const limit = 10;

  function getTableData() {
    setLoader(true);
    getWineryListApi(currentPage, limit, search, type)
      .then((response) => {
        setUsers(response.data.data);

        setLoader(false);
        const total = response.data.data.userCount;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  function onDelete(id) {
    setId(id);
    setShowDeleteModal(true);
  }
  function onAction(id) {
    setLoader(true);
    blockWinery(id)
      .then((response) => {
        getTableData();
        setLoader(false);
        if (response?.data?.data?.isBlocked) {
          toast.success("Winery Disable successfully");
        } else {
          toast.success("Winery Enable successfully");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoader(false);
      });
  }
  async function getExportFileUrl() {
    setIsLoading(true);
    try {
      const response = await gerExportFileApi("wineries");
      setFileUrl(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error, "error");
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  }
  const downloadFile = () => {
    if (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, search, type]);

  return (
    <div>
      <PageTitle activeMenu="Winery Management" motherMenu="List" />
      <div className="d-flex justify-content-between align-items-center">
        <div className="form-group ml-3" style={{ width: "35%" }}>
          <input
            type="text"
            name="table_search"
            className="form-control"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center mr-3">
          <select
            className="form-control mr-2"
            style={{ minWidth: "150px" }}
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">All Category</option>
            <option value="Wine Tasting">Wine Tasting</option>
            <option value="Wine Tours">Wine Tours</option>
            <option value="Others">Other</option>
          </select>
          {fileUrl ? (
            <button
              className="btn btn-primary btn-sm"
              onClick={downloadFile}
              style={{ width: "-webkit-fill-available" }}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Download file"
              )}
            </button>
          ) : (
            <button
              className="btn btn-primary btn-sm"
              onClick={getExportFileUrl}
              style={{ width: "-webkit-fill-available" }}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Export file"
              )}
            </button>
          )}
        </div>
      </div>
      <Col>
        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th className="text-center">
                      <strong>Winery Position</strong>
                    </th>
                    <th>
                      <strong>Image</strong>
                    </th>
                    <th>
                      <strong>NAME</strong>
                    </th>

                    <th>
                      <strong>address</strong>
                    </th>
                    <th>
                      <strong>Category</strong>
                    </th>

                    <th>
                      <strong>vendor</strong>
                    </th>
                    <th className="text-center">
                      <strong>Guests</strong>
                    </th>
                    <th className="text-center">
                      <strong>price</strong>
                    </th>
                    <th>
                      <strong>Created At</strong>
                    </th>
                    <th className="text-center">
                      <strong>STATUS</strong>
                    </th>
                    <th className="text-center">
                      <strong>Enable/Disable</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.users?.map((item, i) => (
                    <tr
                      key={i}
                      className={item.isDeleted ? "disabled-row" : ""}
                    >
                      <td className="text-center">
                        {" "}
                        {item.topPosition || "-"}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: item,
                          })
                        }
                      >
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={`https://api.bookwineries.com/${item?.photo[0]}`}
                            height={50}
                            width={50}
                            style={{ borderRadius: "25px" }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "white", // Change the text color for visibility
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            {item.topPosition}
                          </div>
                        </div>
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: item,
                          })
                        }
                      >
                        {item.name}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: item,
                          })
                        }
                      >
                        {item.address}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: item,
                          })
                        }
                      >
                        {item.category}
                      </td>

                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: item,
                          })
                        }
                      >
                        {item.vendorId.name}
                      </td>
                      <td
                        className="text-center"
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: item,
                          })
                        }
                      >
                        {item.guests}
                      </td>
                      <td
                        className="text-center"
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: item,
                          })
                        }
                      >
                        €{item.price}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: item,
                          })
                        }
                      >
                        {moment(item.createdAt).format("DD/MM/YYYY HH:mm A")}
                      </td>
                      <td>
                        {item?.isBlocked ? (
                          <Badge variant="danger light">Disable</Badge>
                        ) : (
                          <Badge variant="success light">Enable</Badge>
                        )}
                      </td>

                      <td>
                        {item?.isDeleted ? (
                          <Badge variant="danger light">Deleted</Badge>
                        ) : (
                          <label className="switch-disable">
                            <Switch
                              onChange={() => onAction(item._id)}
                              checked={item.isBlocked}
                              onColor={"#FF0000"}
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            />
                          </label>
                        )}
                      </td>
                      <td className="text-center ">
                        <div
                          className="align-items-center"
                          style={{ display: "flex", gap: "8px" }}
                        >
                          <div
                            class="btn btn-danger  btn-xs sharp list-btn"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "red",
                              color: "white",
                            }}
                            onClick={() => onDelete(item._id)}
                          >
                            <i class="fa fa-trash"></i>
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={(props) =>
                              renderTooltip({
                                ...props,
                                content: "Mark for top 5",
                              })
                            }
                          >
                            <div
                              class="btn  btn-xs sharp list-btn"
                              style={{
                                cursor: "pointer",
                                backgroundColor: "green",
                                color: "white",
                              }}
                              onClick={() => {
                                setId(item);
                                setIsSelectWineryModal(true);
                              }}
                            >
                              <i class="fa fa-hand-pointer-o"></i>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {users?.userCount === 0 && !loader ? (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Total Wineries
                  <span
                    style={{
                      color: "#9b4444",
                      border: "1px solid #9b4444",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {users?.userCount}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Col>
      {showDeleteModal && (
        <DeletePopup
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          api={deleteWineryApi}
          id={id}
          successMessage={"Deleted Winery successfully"}
          refresh={getTableData}
        />
      )}
      {isSelectWineryModal && (
        <SelectForTopWineryModal
          show={isSelectWineryModal}
          onHide={() => setIsSelectWineryModal(false)}
          data={id}
          refresh={getTableData}
        />
      )}
    </div>
  );
}
