import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { topWineryApi } from "../../services/Store/StoreServices";

export default function SelectForTopWineryModal({
  show,
  onHide,
  refresh,
  data,
}) {
  const [selectedNumber, setSelectedNumber] = useState(data?.topPosition);
  const [loader, setLoader] = useState(false);

  async function handleSaveWinery(e) {
    e.preventDefault();
    setLoader(true);
    try {
      const postData = {
        wineryId: data?._id,
        number: selectedNumber,
      };
      const response = await topWineryApi(postData);
      toast.success("Saved Success");
      onHide();
      refresh();
    } catch (e) {
      toast.error(e?.response?.data?.data || e.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <div className="">
        <Modal.Header>
          <Modal.Title>Top wineries to explore</Modal.Title>
        </Modal.Header>
        <form className="p-3 pl-4 pr-4" onSubmit={handleSaveWinery}>
          <div className="form-group">
            <label>Select number</label>
            <select
              className="form-control"
              onChange={(e) => setSelectedNumber(e.target.value)}
              value={selectedNumber}
              required
            >
              <option value="">Select...</option>
              {selectedNumber && <option value="0">Unset Position</option>}
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
              <option value="4">Four</option>
              <option value="5">Five</option>
            </select>
          </div>
          <button className="btn btn-primary w-100 btn-sm">
            {loader ? (
              <Spinner animation="border" size="sm" title="Save" />
            ) : (
              "Save"
            )}
          </button>
        </form>
      </div>
    </Modal>
  );
}
