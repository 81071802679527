import React, { useEffect, useState } from "react";
import { Col, Card, Table, Spinner, Badge } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  gerExportFileApi,
  getReferenceUser,
  getUserBooingApi,
} from "../../services/User/UserService";
import toast from "react-hot-toast";
import Pagination from "../common/Pagination";
import moment from "moment";

export default function BookingWinery({ location }) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const winery = location?.state;

  const limit = 10;

  function getTableData() {
    setLoader(true);
    getUserBooingApi(winery?.id && winery?.id, winery?.type, currentPage, limit)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        const total = response.data.data.count;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.data);
        setLoader(false);
      });
  }
  async function getExportFileUrl() {
    setIsLoading(true);
    try {
      const response = await gerExportFileApi("bookings");
      console.log(response);
      setFileUrl(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error, "error");
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  }
  const downloadFile = async () => {
    if (fileUrl) {
      setIsDownloading(true);
      try {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "report.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
        toast.error("Failed to download the file.");
      } finally {
        setIsDownloading(false);
      }
    }
  };

  console.log(data);
  useEffect(() => {
    getTableData();
  }, [currentPage]);

  return (
    <>
      <div className="d-flex align-items-baseline justify-content-between">
        <h3 className="p-3">{winery?.pageName}</h3>
        <div>
          {fileUrl ? (
            <button className="btn btn-primary btn-sm" onClick={downloadFile}>
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Download file"
              )}
            </button>
          ) : (
            <button
              className="btn btn-primary btn-sm"
              onClick={getExportFileUrl}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Export file"
              )}
            </button>
          )}
        </div>
      </div>
      <Col>
        <Card>
          <Card.Body>
            {loader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>BookingId</strong>
                    </th>
                    <th>
                      <strong>User Name</strong>
                    </th>
                    <th>
                      <strong>VendorId</strong>
                    </th>
                    <th>
                      <strong>winery name</strong>
                    </th>
                    <th className="text-center">
                      <strong>category</strong>
                    </th>
                    <th className="text-center">
                      <strong>Guests</strong>
                    </th>
                    <th>
                      <strong>sub total</strong>
                    </th>
                    <th>
                      <strong>gift card Amount</strong>
                    </th>

                    <th>
                      <strong>total</strong>
                    </th>
                    <th>
                      <strong>Booking Slot</strong>
                    </th>
                    <th>
                      <strong>Date</strong>
                    </th>
                    <th className="text-center">
                      <strong>Cancelled Date & Time</strong>
                    </th>
                    <th>
                      <strong>Status</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.bookings?.map((item, refIndex) => (
                    <tr key={refIndex}>
                      <td>{item?._id}</td>

                      {item?.isVendor ? (
                        <td>Vendor</td>
                      ) : (
                        <td>
                          {item?.userId?.name}
                          <br />
                          {item?.userId?.email}
                          <br />
                          {item?.userId?.countryCode ? "+" : ""}
                          {item?.userId?.countryCode} {item?.userId?.contact}
                        </td>
                      )}
                      <td>{item?.vendorId?._id}</td>

                      <td>{item?.wineryId?.name}</td>
                      <td className="text-center">
                        {item?.wineryId?.category}
                      </td>
                      <td className="text-center">{item?.guests || "-"}</td>
                      <td className="text-center">
                        {item?.subTotal ? "€" + item?.subTotal : "-"}
                      </td>
                      <td className="text-center">
                        {item?.giftCardAmount
                          ? "€" + item?.giftCardAmount
                          : "-"}
                      </td>

                      <td className="text-center">€{item?.amount}</td>

                      <td>
                        {moment(item?.startTime, "HH:mm:ss").format("hh:mmA") ||
                          "N/A"}
                        -
                        {
                          (item?.endTime,
                          moment(item?.endTime, "HH:mm:ss").format("hh:mmA"))
                        }
                      </td>

                      <td>{moment(item?.date).format("DD/MM/YYYY")}</td>
                      {item?.cancelledDate ? (
                        <td className="text-center">
                          {moment(item?.cancelledDate).format(
                            "DD/MM/YYYY HH:mm A"
                          )}
                        </td>
                      ) : (
                        <td className="text-center">-</td>
                      )}
                      {item?.isVendor ? (
                        <td>
                          <Badge variant="primary light">Manual</Badge>
                        </td>
                      ) : (
                        <td>
                          {item.status === "confirm" ? (
                            <Badge variant="success light">Completed</Badge>
                          ) : item.status === "cancel" ? (
                            <Badge variant="danger light">
                              {item?.cancelledBy}
                            </Badge>
                          ) : (
                            <Badge variant="info light">Upcoming</Badge>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {data?.count === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {data?.count !== 0 && !loader && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Total Bookings:
                  <span
                    style={{
                      color: "#9b4444",
                      border: "1px solid #9b4444",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {data?.count}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
