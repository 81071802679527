import moment from "moment";
import React from "react";
import { Badge, Card, Col, Spinner, Table } from "react-bootstrap";

export default function CodeUsed(props) {
  const data = props?.location?.state?.item;
  return (
    <div>
      <div>
        <h3 className="p-3">Gift Cards History</h3>
      </div>
      <Col>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>bookingId</strong>
                  </th>
                  <th>
                    <strong>Winery Name</strong>
                  </th>
                  <th className="text-center">
                    <strong>Slot</strong>
                  </th>
                  <th className="text-center">
                    <strong>guest</strong>
                  </th>
                  <th className="text-center">
                    <strong>gift Card Amount</strong>
                  </th>

                  <th className="text-center">
                    <strong>Date</strong>
                  </th>
                  <th className="text-center">
                    <strong>status</strong>
                  </th>
                </tr>
              </thead>

              <tbody>
                {data?.bookingId?.map((item, refIndex) => (
                  <tr key={refIndex}>
                    <td>{item?._id}</td>

                    <td>{item?.wineryId?.name}</td>
                    {/* <td> {item?.email}</td> */}
                    <td className="text-center">
                      {moment(item?.startTime, "HH:mm:ss").format("hh:mmA") ||
                        "N/A"}
                      -
                      {
                        (item?.endTime,
                        moment(item?.endTime, "HH:mm:ss").format("hh:mmA"))
                      }
                    </td>
                    <td className="text-center"> {item?.guests}</td>

                    <td className="text-center">
                      €{item?.giftCardAmount || 0}
                    </td>

                    {/* <td className="text-center">€{item?.amount}</td> */}

                    <td className="text-center">
                      {moment(item?.date).format("DD/MM/YYYY")}
                    </td>
                    {item?.isVendor ? (
                      <td>
                        <Badge variant="primary light">Manual</Badge>
                      </td>
                    ) : (
                      <td>
                        {item.status === "confirm" ? (
                          <Badge variant="success light">Completed</Badge>
                        ) : item.status === "cancel" ? (
                          <Badge variant="danger light">
                            {item?.cancelledBy}
                          </Badge>
                        ) : (
                          <Badge variant="info light">Upcoming</Badge>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {data?.bookingId?.length === 0 && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
