import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { CHANGE_PASSWORD, DASHBOARD, LOGIN, LOGOUT } from "./AuthApiEndPoints";
import axiosInstance from "./AxiosInstance";
import swal from "sweetalert";

export function signUp(email, password, name) {
  const postData = {
    email,
    password,
    name,
    // type: "Vendor",
  };

  return axiosInstance.post(`admin/auth/logout`, postData);
}

export function login(email, password) {
  const postData = {
    email,
    password,
    // type: "Vendor",
  };

  return axiosInstance.post("admin/auth/signin", postData);
}
export function otpVerify(otp) {
  const otpToken = localStorage.getItem("otp_token");
  const postData = {
    otp,
  };

  return axios.post(
    "https://api.bookwineries.com/admin/auth/verifyOtp",
    postData,
    {
      headers: {
        Authorization: `Bearer ${otpToken}`,
      },
    }
  );
}

export function dashboard() {
  return axiosInstance.get(DASHBOARD);
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD, postData);
}
export function logOut() {
  return axiosInstance.post(LOGOUT, { test: "" });
}

export function formatError(errorMessage) {
  if (errorMessage) {
    swal("Oops", errorMessage, "error");
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("wineryToken", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("wineryToken");
  // let tokenDetails = "";
  if (!tokenDetailsString) {
    history.push("/");
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}
