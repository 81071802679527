import React, { useEffect, useState } from "react";
import { Col, Card, Table, Spinner } from "react-bootstrap";
import moment from "moment";
import PageTitle from "../layouts/PageTitle";
import {
  getReferenceUser,
  payoutReferPaymentApi,
} from "../../services/User/UserService";
import toast from "react-hot-toast";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Swal from "sweetalert2";

export default function ReferenceManagement({ location }) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const userId = location?.state?.userId;

  function getTableData() {
    setLoader(true);
    getReferenceUser(userId)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        // const total = response.data.data.total;
        // setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.data || error?.response?.data?.message
        );
        setLoader(false);
      });
  }

  async function handlePayoutPayment(refFromUserId, refUserId) {
    let proceed = true;

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Payout this commission?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Payout",

      cancelButtonText: "Cancel !",
    });

    proceed = result.isConfirmed;

    if (proceed) {
      try {
        const postData = {
          refFromUserId,
          refUserId,
        };
        const res = await payoutReferPaymentApi(postData);
        toast.success("Payout confirmed");
        getTableData();
      } catch (err) {
        toast.error(
          err?.response?.data?.data
            ? err?.response?.data?.data
            : err?.response?.data?.message
        );
      } finally {
      }
    }
  }
  console.log(data);
  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div>
      <div className="page-titles">
        <h4>Referred Users</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/user-management">User Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Referred Users</Link>
          </li>
        </ol>
      </div>
      <Col>
        <Card>
          <Card.Body>
            {loader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th className="text-center">
                      <strong>Email</strong>
                    </th>
                    <th className="text-center">
                      <strong>contact</strong>
                    </th>
                    <th className="text-center">
                      <strong>Prize</strong>
                    </th>
                    <th>
                      <strong>action</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.users?.map((user, userIndex) => (
                    <React.Fragment key={userIndex}>
                      {user?.refUser?.map((item, refIndex) => (
                        <tr
                          key={`${userIndex}-${refIndex}`}
                          className={item.isDeleted ? "disabled-row" : ""}
                        >
                          <td>{item.name}</td>
                          <td className="text-center">{item.email}</td>
                          <td className="text-center">
                            <span>+{item.countryCode} </span>
                            {item.contact}
                          </td>
                          <td className="text-center">${item?.refPayment}</td>
                          <td>
                            <button
                              className="btn btn-primary btn-xs"
                              disabled={item?.refPayment === 0}
                              onClick={() =>
                                handlePayoutPayment(user?._id, item?._id)
                              }
                            >
                              {loader ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                "Payout"
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            )}
            {data?.refUserCount === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
