// Menu
import MetisMenu from "metismenujs";
import React, { useEffect } from "react";
// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
// Link and useNavigate from react-router-dom
import { Link, useHistory } from "react-router-dom";

const MM = ({ children }) => {
  useEffect(() => {
    const $el = document.querySelector(".metismenu");
    new MetisMenu($el);
  }, []);

  return (
    <div className="mm-wrapper">
      <ul className="metismenu">{children}</ul>
    </div>
  );
};

const SideBar = () => {
  const history = useHistory(); // useNavigate hook for navigation

  useEffect(() => {
    const btn = document.querySelector(".nav-control");
    const mainWrapper = document.querySelector("#main-wrapper");
    const toggleFunc = () => mainWrapper.classList.toggle("menu-toggle");
    btn.addEventListener("click", toggleFunc);

    return () => btn.removeEventListener("click", toggleFunc); // Cleanup listener on unmount
  }, []);

  let path = window.location.pathname.split("/").pop(); // Get current path

  // Menu items
  const dashBoard = [
    "",
    "analytics",
    "events",
    "order-list",
    "general-customers",
    "reviews",
    "task",
  ];
  const users = ["user-management", "user-details", "reference-management"];
  const winery = ["winery-management", "winery-details"];
  const TotalBooking = ["booking-winery"];
  const Vendor = ["vendor-management", "pending-vendors", "vendor-details"];
  const GiftCard = ["gift-card-management"];
  const Policies = ["booking-cancellation-policy", "terms-condition"];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {/* Dashboard */}
          <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/">
              <i className="flaticon-381-networking"></i>
              <span className="nav-text" style={{ paddingLeft: "-0.5rem" }}>
                Dashboard
              </span>
            </Link>
          </li>

          {/* User Management */}
          <li className={`${users.includes(path) ? "mm-active" : ""}`}>
            <Link to="user-management" className="ai-icon">
              <i className="fa fa-users"></i>
              <span className="nav-text" style={{ paddingLeft: "-0.5rem" }}>
                User Management
              </span>
            </Link>
          </li>

          {/* Winery Management */}
          <li className={`${winery.includes(path) ? "mm-active" : ""}`}>
            <Link to="/winery-management" className="ai-icon">
              <i className="flaticon-381-photo"></i>
              <span className="nav-text" style={{ paddingLeft: "0rem" }}>
                Winery Management
              </span>
            </Link>
          </li>

          {/* Vendor Management */}
          <li className={`${Vendor.includes(path) ? "mm-active" : ""}`}>
            <Link to="/vendor" className="has-arrow ai-icon">
              <i className="fa fa-users"></i>
              <span className="nav-text" style={{ paddingLeft: "-0.5rem" }}>
                Vendor Management
              </span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "pending-vendors" ? "mm-active" : ""}`}
                  onClick={() => history.push("/pending-vendors")}
                >
                  New Vendor Requests
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "vendor-management" ? "mm-active" : ""
                  }`}
                  onClick={() => history.push("/vendor-management")}
                >
                  Vendors List
                </Link>
              </li>
            </ul>
          </li>

          <li className={`${GiftCard.includes(path) ? "mm-active" : ""}`}>
            <Link to="/gift-card-management" className="ai-icon">
              <i className="flaticon-381-gift"></i>
              <span className="nav-text" style={{ paddingLeft: "0rem" }}>
                Gift Cards
              </span>
            </Link>
          </li>

          {/* Total Bookings */}
          <li
            className={`${TotalBooking.includes(path) ? "mm-active" : ""}`}
            onClick={() =>
              history.push({
                pathname: "/booking-winery",
                state: {
                  type: "total",
                  pageName: "Total Bookings",
                },
              })
            }
          >
            <Link to="#" className="ai-icon">
              <i className="flaticon-381-list-1"></i>
              <span className="nav-text" style={{ paddingLeft: "0rem" }}>
                Total Bookings
              </span>
            </Link>
          </li>

          {/* Policies */}
          <li className={`${Policies.includes(path) ? "mm-active" : ""}`}>
            <Link to="/policy" className="has-arrow ai-icon">
              <i className="flaticon-381-settings"></i>
              <span className="nav-text" style={{ paddingLeft: "-0.5rem" }}>
                Policies
              </span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${
                    path === "booking-cancellation-policy" ? "mm-active" : ""
                  }`}
                  onClick={() => history.push("/booking-cancellation-policy")}
                >
                  Booking Cancellation Policy
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "terms-condition" ? "mm-active" : ""}`}
                  onClick={() => history.push("/terms-condition")}
                >
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
