import React, { useEffect, useState } from "react";
import pin from "../../icons/pin.png";
import "./WineryDetails.css";
// import heart from "../../assets/svg/heart.svg";
import imagePlaceholder from "../../images/emptyImg.jpg";
import starIcon from "../../icons/star.png";
import moment from "moment";
import { Card, Spinner } from "react-bootstrap";
import CountCard from "../components/CountCard";
import { getWineryDetailsApi } from "../../services/Store/StoreServices";
import DeletePopup from "../modal/DeletePopup";
import { deleteReviewApi } from "../../services/User/UserService";
import RejectVendorMessageModal from "../modal/RejectVendorMessageModal";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);
export default function WineryDetails(props) {
  const wineryId = props.location.state;
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const imgUrl = "https://api.bookwineries.com/";

  function getTableData() {
    setLoader(true);
    getWineryDetailsApi(wineryId?._id)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, [wineryId]);

  const numberOfStars = typeof data?.averageStars ? data.averageStars : 0;

  const fullStars = Math.floor(numberOfStars);
  const hasHalfStar = numberOfStars % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  const starElements = [
    ...Array.from({ length: fullStars }, (_, index) => (
      <div key={`full-${index}`} className="full-star me-2" />
    )),
    ...(hasHalfStar ? [<div key="half" className="half-star me-2" />] : []),
  ];

  return (
    <>
      {loader ? (
        <Spinner animation="border" />
      ) : (
        <div className="content-margin mt-1">
          <div
            className="d-flex justify-content-between p-2 "
            style={{ gap: "25px" }}
          >
            <CountCard
              title={"Total Bookings"}
              count={data?.totalWinery}
              id={data?._id}
              type={"total"}
            />
            <CountCard
              title={"Upcoming Bookings"}
              count={data?.activeBookingsCount}
              id={data?._id}
              type={"active"}
            />
            <CountCard
              title={"Completed Bookings"}
              count={data?.confirmedCount}
              id={data?._id}
              type={"confirm"}
            />

            <CountCard
              title={"Cancelled Bookings"}
              count={data?.cancelCount}
              id={data?._id}
              type={"cancel"}
            />
          </div>
          <div className="d-block d-sm-flex justify-content-between mt-3">
            <div className="item-details">
              <h3>{data?.name}</h3>
              <p>
                <img src={pin} height={20} alt="Location" /> {data?.address}
              </p>
              <p>
                <div className="star-container">{starElements}</div>
                {data?.reviews?.length} Reviews
              </p>
            </div>
            <div className="item2-details mb-0">
              <p>
                €{data?.price}
                <span className="small-text">/per person</span>
              </p>
              <div className="d-flex">
                <h5>Guests -</h5>
                <h5 className="ml-2">{data?.guests}</h5>
              </div>
            </div>
          </div>
          <div className="image-section">
            <img
              src={data?.photo?.[0] ? imgUrl + data.photo[0] : ""}
              alt="main"
            />
            <div className="image-box">
              <div className="image-box-internal">
                <img
                  src={data?.photo?.[1] ? imgUrl + data.photo[1] : ""}
                  alt="1"
                />
                <img
                  src={data?.photo?.[2] ? imgUrl + data.photo[2] : ""}
                  alt="2"
                />
              </div>
              <div className="image-box-internal-2">
                <img
                  src={data?.photo?.[3] ? imgUrl + data.photo[3] : ""}
                  alt="3"
                />
                <img
                  src={data?.photo?.[4] ? imgUrl + data.photo[4] : ""}
                  alt="4"
                />
              </div>
            </div>
          </div>
          <div className="item-details-section">
            <div className="col-12 col-md-6">
              <h1 className="pb-1 m-0">Overview</h1>
              <p className="m-0">{data?.overview}</p>

              <h1 className="pt-3 pb-2">Amenities</h1>
              <div className="d-flex justify-content-between align-items-start gap-3">
                <div>
                  {data?.amenities
                    ?.slice(0, Math.ceil(data?.amenities.length / 2))
                    ?.map((amenity, index) => (
                      <p key={index}>~ {amenity}</p>
                    ))}
                </div>
                <div>
                  {data?.amenities
                    ?.slice(Math.ceil(data?.amenities.length / 2))
                    ?.map((amenity, index) => (
                      <p key={index}>~ {amenity}</p>
                    ))}
                </div>
              </div>
              <h1 className="pt-3 pb-2">Vendor Details</h1>
              <div className="d-flex justify-content-between align-items-center">
                <span>Name</span>
                <span>{data?.vendorId?.name}</span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span>Email</span>
                <span>{data?.vendorId?.email}</span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span>Phone</span>
                <span>
                  +<span>{data?.vendorId?.countryCode} </span>
                  {data?.vendorId?.contact}
                </span>
              </div>
            </div>
            <div className="w-100">
              <Card className="slot-card">
                <Card.Header>
                  <h3 className="m-0">Times Slots</h3>
                </Card.Header>

                <div className="mt-3" style={{ gap: "5px" }}>
                  {data?.openingHours &&
                    data.openingHours?.map((item, index) => (
                      <>
                        <p>{item?.day}</p>

                        {item?.slots?.map((slot) => (
                          <button
                            className="btn btn-outline-secondary me-2 mb-2 mr-2"
                            disabled
                            key={slot?._id}
                          >
                            {moment(slot?.startTime, "HH:mm").format("hh:mm a")}{" "}
                            - {moment(slot?.endTime, "HH:mm").format("hh:mm a")}
                          </button>
                        ))}
                      </>
                    ))}
                </div>
              </Card>
            </div>
          </div>
          <div className="review-section">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Reviews</h3>
              {data?.slot?.isCompleted && (
                <button className="wishlist-btn">Give your review</button>
              )}
            </div>
            {data?.reviews?.map((review) => (
              <ReviewCard
                key={review?._id}
                review={review}
                getTableData={getTableData}
              />
            ))}

            {data?.reviews?.length === 0 && (
              <div className="text-center p-5">No Reviews!</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function ReviewCard({ review, getTableData }) {
  const numberOfStars = typeof review?.star ? review?.star : 0;
  const [id, setId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  function handleError(e) {
    e.target.src = imagePlaceholder;
  }
  function onDelete(id) {
    setId(id);
    setShowDeleteModal(true);
  }

  console.log(review);

  const yellowStars = Array.from({ length: numberOfStars }, (_, index) => (
    <img
      key={index}
      src={starIcon}
      width={20}
      alt="Yellow Star"
      className="mr-2"
    />
  ));

  return (
    <div
      className={`review-card d-flex justify-content-between align-items-start p-3 ${
        review?.isDeleted ? "disabled-row" : ""
      }`}
    >
      {" "}
      <div>
        <div className="d-flex justify-content-start align-items-start gap-2">
          <img
            src={`https://api.bookwineries.com/${review?.userId?.profilePicture}`}
            className="circle mr-2"
            alt="Reviewer"
            onError={handleError}
          />
          <div>
            <span>{review?.userId?.name}</span>
            <p>
              {yellowStars} {moment(review?.time).fromNow()}
            </p>
          </div>
        </div>
        <p className="review-text m-0">{review?.message}</p>
        {review?.isDeleted && (
          <p className="review-text m-0">Reason : {review?.reason}</p>
        )}
      </div>
      <div
        class="btn btn-danger btn-xs sharp list-btn"
        style={{
          cursor: "pointer",
          backgroundColor: "red",
          color: "white",
        }}
        onClick={() => onDelete(review)}
      >
        <i class="fa fa-trash"></i>
      </div>
      {showDeleteModal && (
        <DeletePopup
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          id={id}
          successMessage="Deleted successfully"
          api={deleteReviewApi}
          refresh={getTableData}
        />
      )}
      {showDeleteModal && (
        <RejectVendorMessageModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          data={id}
          successMessage="Deleted successfully"
          refresh={getTableData}
          api={deleteReviewApi}
        />
      )}
    </div>
  );
}
