import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

function DeletePopup({ id, api, refresh, successMessage, show, onHide }) {
  const [loader, setLoader] = useState(false);

  const handleConfirmDelete = () => {
    setLoader(true);
    api(id)
      .then((response) => {
        refresh();
        toast.success(successMessage);
        setLoader(false);
        onHide(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.data);
        setLoader(false);
        onHide(false);
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="d-flex flex-column align-items-center">
          <div className="popup-warning-icon">!</div>
          <h3>Are you sure?</h3>
          <p>You won't be able to revert this!</p>
          <div className="pt-4 d-flex justify-content-center">
            <Button
              variant="danger"
              className="btn-sm"
              onClick={handleConfirmDelete}
            >
              {loader ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Yes, delete it!"
              )}
            </Button>
            <Button
              variant="secondary"
              className="btn-sm ml-2"
              onClick={() => onHide()}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeletePopup;
