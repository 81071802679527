import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function CountCard({ title, count, type, id }) {
  const history = useHistory();
  return (
    <div
      onClick={() =>
        history.push({
          pathname: "/booking-winery",
          state: {
            id: id,
            type: type,
            pageName: title,
          },
        })
      }
    >
      <div className="reviewer-box">
        <div className="media align-items-center">
          <div className="reviewer-image">
            <i className="flaticon-381-list-1"></i>
            {/* </span> */}
          </div>
          <div className="media-body pl-3">
            <h4 className="mt-0 mb-1 text-white">{title}</h4>
            <h4 className="mt-0 mb-1 text-white">{count}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
