import {
  formatError,
  login,
  otpVerify,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import { toast } from "react-hot-toast";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, name, history, setLoader) {
  setLoader(true);

  return (dispatch) => {
    signUp(email, password, name)
      .then((response) => {
        dispatch(confirmedSignupAction(response.data));

        setLoader(false);
        toast.success("Created account successfully");
        history.push("/login");
      })
      .catch((error) => {
        const errorMessage = formatError(error?.response?.data?.message);
        dispatch(signupFailedAction(errorMessage));
        setLoader(false);
      });
  };
}

export function logout(history) {
  localStorage.removeItem("wineryToken");
  localStorage.removeItem("otp_token");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(otp, history, setLoader) {
  setLoader(true);
  return (dispatch) => {
    otpVerify(otp)
      .then((response) => {
        if (response) {
          console.log(response.data.data);
          saveTokenInLocalStorage(response?.data?.data?.token);
          setLoader(false);
        }
        dispatch(loginConfirmedAction(response?.data?.data?.token));
        history.push("/");
      })
      .catch((error) => {
        const errorMessage = formatError(
          error?.response?.data?.message || error.response?.data?.data
        );
        setLoader(false);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
